import { APIDataHandler } from "@core/models/data.handler";
import { IRequire, IRequireAuthInfoResponse } from "./require-auth-info.response";
import { forEach } from "lodash";

export interface IRequireAuthInfo extends IRequire { }
export class RequireAuthInfoModel implements APIDataHandler<IRequireAuthInfo>{
  TYPE_REQUIRE_NAME = 'require';
  TYPE_WARNING_NAME = 'warning';

  public static REQUIRE_TO_UPDATE_PHONE_KEY = 'ChangePhone';
  public static REQUIRE_TO_UPDATE_PASSWORD = 'ChangePassword';
  public static REQUIRE_TO_UPDATE_PROFILE = 'UpdateProfile';
  public static REQUIRE_TO_UPDATE_EMAIL_KEY = 'VerifyEmail';

  mapDataFromAPI(response: IRequire): IRequireAuthInfo {
    return { ...response };
  }

  mapArray(response: IRequireAuthInfoResponse) {
    return forEach(response.result, (req) => this.mapDataFromAPI(req));
  }

  shouldNavigateToUpdateAuthInfo = (requires: Array<IRequire>): boolean => {
    const requireList = this.getFirstRequire(requires);
    return requireList !== undefined;
  }

  getFirstRequire(requires: Array<IRequire>) {
    return requires.find((value) =>
      value.status == 'false' && value.type == this.TYPE_REQUIRE_NAME
    );
  }
}
