import { isDevMode } from "@angular/core";
import { ErrorBase } from "@core/models/error.model";

export enum RequireAuthInfoErrorCode {
  NO_API = 'NO_API',
  UNAUTHORIZE = 'UNAUTHORIZE',
}
export class RequireAuthInfoError {
  public static handle(error: ErrorBase) {
    isDevMode() && console.error(`Error when get requirement of account after login`)

    if (error.code == -1) {
      isDevMode() && console.error(`Should update perrmission for this api`);
      throw new Error(RequireAuthInfoErrorCode.NO_API);
    };

    if (error.code == 403) {
      isDevMode() && console.error(`Should update perrmission for this api in notion`);
      throw new Error(RequireAuthInfoErrorCode.UNAUTHORIZE);
    }
  }
}
