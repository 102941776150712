import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, of, tap, throwError } from "rxjs";
import { API_ENDPOINTS } from "@config/api.config";
import { RequireAuthInfoError } from "../models/require-auth-info-error";
import { IRequire, IRequireAuthInfoResponse } from "../models/require-auth-info.response";
import { AppStorageService, StorageLocation } from "@core/app-store/app-storage.service";
import { RequireAuthInfoModel } from "../models/require-auth-info.model";

@Injectable({
  providedIn: 'root',
})
export class RequireUpdateAuthInfoService {
  public httpClient = inject(HttpClient);
  private readonly appStorage = inject(AppStorageService);

  public API_ENDPOINT = `${API_ENDPOINTS.authen}/user/require/first-login/get-requirement`;

  private readonly STORAGE_KEY = this.appStorage.localStorageKeys.feature.auth.firstLoginVerify;
  private readonly STORAGE_KEY_ERROR = this.appStorage.localStorageKeys.feature.auth.firstLoginVerifyError;
  private readonly LOCATION = { location: StorageLocation.SESSION_STORAGE };

  requireAuthInfoModel = new RequireAuthInfoModel();

  getRequire() {
    if(this.getError()) {
      return of([]);
    }

    const requireInStorage = this.appStorage.getItem(this.STORAGE_KEY, this.LOCATION);
    if (requireInStorage) {
      return of(requireInStorage);
    }
    return this.httpClient.get<IRequireAuthInfoResponse>(this.API_ENDPOINT).pipe(
      map((response) => {
        return this.requireAuthInfoModel.mapArray(response);
      }),
      tap((require) => {
        this.appStorage.setItem(this.STORAGE_KEY, require, this.LOCATION);
      }),
      catchError((err) => throwError(() => RequireAuthInfoError.handle(err))),
    );
  }

  getRequireListFromStorage(require: boolean) {
    const requireInStorage =
      this.appStorage.getItem(this.STORAGE_KEY, this.LOCATION) as Array<IRequire>;
    if (requireInStorage) {
      return requireInStorage.find(
        (value) => value.type == this.requireAuthInfoModel.TYPE_REQUIRE_NAME
          && value.status == (require ? 'true' : 'false'),
      );
    };
    return undefined;
  }

  setError(isError: boolean) {
    this.appStorage.setItem(this.STORAGE_KEY_ERROR, isError, this.LOCATION);
  }
  getError() {
    return this.appStorage.getItem(this.STORAGE_KEY_ERROR, this.LOCATION) ?? false;
  }

  clearAll() {
    this.appStorage.removeItems([this.STORAGE_KEY,], this.LOCATION);
  }
}
